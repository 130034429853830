/* eslint-disable jsx-a11y/anchor-has-content */
// Libs
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import RenderMaps from '../../home/maps';

// Components
import { device } from '../../../device';

// Images
import arrowRight from 'images/icons/arrow-right.svg';
import iconWaze from 'images/icons/waze.svg';

// Styles
const Container = styled.section`
  grid-area: ANT;

  display: flex;
  flex-direction: column;
  margin-bottom: ${props => (props.isDetails ? '0px' : '4.5rem')};
  background: #fff;
  font-size: 1rem;
  text-align: left;
  line-height: 1.75rem;

  a {
    color: #45a7df;
  }

  @media ${device.laptop} {
    width: 100%;
    margin-bottom: ${props => (props.isDetails ? '0px' : '1.875rem')};
    border-top: 0.063rem solid #c6c8cc;
  }

  @media ${device.tablet} {
    margin-left: 0;
  }
`;

const MapTitleBox = styled.div`
  display: ${props => (props.isDetails ? 'flex' : 'block')};
  flex-direction: column-reverse;

  @media ${device.tablet} {
    display: block;
  }
`;

const Title = styled.div`
  margin: ${props => (props.isDetails ? '2.5rem 0 1.6875rem' : '3.125rem 0')};
  text-align: left;

  > p {
    font-size: 1.5rem;
    font-weight: 400;
  }

  @media ${device.tablet} {
    > p {
      font-size: 1.125rem;
      width: 85vw;
    }
  }

  @media ${device.mobile} {
    width: 85vw;
    margin: 1.875rem 0;
  }
`;

const Mapa = styled.div`
  margin-bottom: ${props => (props.isDetails ? '0' : '1.875rem')};
  width: 100%;
  height: 15.625rem;
  background-image: url(${props => props.mapImage && props.mapImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  img {
    width: 100%;
    object-fit: cover;
    height: -webkit-fill-available;
  }

  @media ${device.mobile} {
    margin-bottom: 1.3rem;
  }
`;
const Address = styled.div`
  margin-bottom: 1.875rem;
  font-size: 1rem;

  a {
    margin-left: ${props => props.isMarginLeft && '5px'};
  }

  em {
    font-style: normal;
    width: ${props => props.isAdjustmentWazeButton && '100%'};
    a {
      display: none;
      @media ${device.laptop} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem;
        margin: 1.875rem 0;
        border: 0.063rem solid #c6c8cc;
        border-radius: 6.25rem;
        outline: none;
        background: transparent;
        font-size: 0.875rem;
        color: #45a7df;
        height: 3.5625rem;

        > img {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.625rem;
        }
      }
    }
  }

  @media ${device.tablet} {
    font-size: 0.875rem;
  }

  @media ${device.mobile} {
    width: 100%;
  }

  > h3 {
    margin-top: ${props =>
      props.marginTopH3
        ? '1.8125rem'
        : props.isDetails
        ? '1.8125rem'
        : props.isAdjustmentWazeButton
        ? '0'
        : '2rem'};
    margin-bottom: ${props => props.marginButtonH3 || '0.4rem'};
    font-size: 1rem;
    font-weight: 600;

    @media ${device.tablet} {
      font-size: 0.875rem;
    }
  }

  @media ${device.tablet} {
    h3:first-of-type {
      margin-top: ${props => props.noMarginTopH3Mob && '0rem'};
    }
  }

  > p {
    font-size: 1rem;
    line-height: 1.625rem;

    + p {
      margin-bottom: ${props => (props.isAdjustmentWazeButton ? '0' : '30px')};
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }

    @media ${device.tablet} {
      font-size: 0.875rem;
    }

    > strong {
      margin-left: 0.3rem;
      font-weight: normal;
      color: #45a7df;
    }

    &:last-child {
      margin-top: ${props => props.isWhatsapp && '1.25rem'};
      display: flex;
      align-items: center;

      > img {
        margin-right: 0.625rem;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

const Attention = styled.div`
  margin-bottom: ${props => (props.isDetails ? '3.125rem' : '1.875rem')};
  padding: 1.25rem;
  width: 100%;
  display: ${props => (props.isShowingAttention ? 'none' : 'flex')};
  flex-direction: column;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  background: #ef51630d;

  > span {
    font-size: 0.875rem;
    font-weight: 600;
  }

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

const WazeButton = styled.button`
  display: none;

  @media ${device.laptop} {
    display: ${props => (props.notButton ? 'none' : 'flex')};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1.875rem;
    border: 0.063rem solid #c6c8cc;
    border-radius: 6.25rem;
    outline: none;
    background: transparent;
    font-size: 0.875rem;
    color: #45a7df;

    > img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.625rem;
    }
  }
  @media ${device.mobile} {
    padding: 0.85rem;
    > img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.625rem;
    }
  }
`;

const Information = styled.div`
  display: ${props => props.isShowingAttention && 'none'};
  margin-bottom: 1.875rem;
  font-size: 1rem;
  line-height: 1.625rem;

  @media ${device.tablet} {
    width: 100%;
    font-size: 0.875rem;
  }

  @media ${device.mobile} {
    width: 83vw;
    margin-bottom: ${props => (props.first ? '1.4rem' : '1.75rem')};
  }
`;

const InformationTitle = styled.p`
  font-weight: 600;
`;

const ButtonAboutUnity = styled.div`
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  color: #45a7df;
  :hover {
    text-decoration: underline;
  }

  @media ${device.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    font-size: 0.875rem;
    border-radius: 6.25rem;
    color: #fff;
    background: #45a7df;
    transition: 0.6s background;

    > img {
      display: none;
    }

    :hover {
      background: #1d52a3;
      text-decoration: none;
    }
  }

  @media ${device.mobile} {
    margin: 0;
  }
`;

function AddressTimes({
  fragments,
  markdown,
  isDetails,
  isShowingAttention,
  isMarginLeft,
  isWhatsapp,
  brasiliaDetalhesPage,
  notButton,
  containerMarginBottom,
  location,
  zoom,
  hasWazeButton,
  isAdjustmentWazeButton,
  mapPreview,
  isStatic,
  marginTopH3,
  marginButtonH3,
  noMarginTopH3Mob,
  isDesktop = true,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const textPage = (fragments && fragments[0]) || undefined;
  const wazeLink =
    textPage.singletexts.filter(item => item.includes('https')) || undefined;

  function LinkRenderer({ href, children }) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  const [scriptReady, setScript] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&language=pt-BR`;
    script.addEventListener('load', () => {
      setScript(true);
    });
    document.body.appendChild(script);
  }, []);

  const updateSize = () => {
    setIsMobile(window.innerWidth < 648);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
      setIsMobile(window.innerWidth < 648);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  return (
    <Container
      isDetails={isDetails}
      containerMarginBottom={containerMarginBottom}
    >
      <MapTitleBox isDetails={isDetails}>
        <Title isDetails={isDetails}>
          <p>{isDetails ? 'Endereço' : 'Endereço e Horários'}</p>
        </Title>
        <Mapa isDetails={isDetails} mapImage={mapPreview}>
          {isStatic && isMobile && (
            <RenderMaps
              zoom={zoom}
              defaultCenter={location}
              location={location}
              scriptReady={scriptReady}
            />
          )}
        </Mapa>
        <a href={wazeLink} target="_blank" rel="noopener noreferrer" />
      </MapTitleBox>
      <Address
        isAdjustmentWazeButton={isAdjustmentWazeButton}
        isDetails={isDetails}
        isWhatsapp={isWhatsapp}
        brasiliaDetalhesPage={brasiliaDetalhesPage}
        isMarginLeft={isMarginLeft}
        marginTopH3={marginTopH3}
        marginButtonH3={marginButtonH3}
        noMarginTopH3Mob={noMarginTopH3Mob}
      >
        <ReactMarkdown
          renderers={{ link: LinkRenderer }}
          children={markdown || '-'}
        />
      </Address>
      {textPage && (
        <Attention
          isShowingAttention={isShowingAttention}
          isDetails={isDetails}
        >
          <span>
            <p>{textPage.singletexts[0] || '-'}</p>
          </span>
          <p>{textPage.singletexts[1] || '-'}</p>
        </Attention>
      )}
      {!hasWazeButton && (
        <a href={wazeLink} target="_blank" rel="noopener noreferrer">
          <WazeButton notButton={notButton}>
            <img src={iconWaze} alt="Waze" />
            Dirigir com Waze
          </WazeButton>
        </a>
      )}
      {!isDetails && textPage && (
        <>
          <Information first isShowingAttention={isShowingAttention}>
            <InformationTitle>
              {textPage.singletexts[2] || '-'}
            </InformationTitle>
            <p>{textPage.singletexts[3] || '-'}</p>
          </Information>
          <Information isShowingAttention={isShowingAttention}>
            <InformationTitle>
              {textPage.singletexts[4] || '-'}
            </InformationTitle>
            <p>{textPage.singletexts[5] || '-'}</p>
          </Information>
          <Link to={'detalhes'}>
            <ButtonAboutUnity>
              <p>Saiba mais sobre essa unidade</p>
              <img src={arrowRight} alt="Saiba mais sobre essa unidade" />
            </ButtonAboutUnity>
          </Link>
        </>
      )}
    </Container>
  );
}

export default AddressTimes;
