// Libs
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from '../../../device';

// Styles
const Container = styled.section`
  grid-area: ABT;
  height: auto;
  display: flex;
  justify-content: space-around;

  @media ${device.desktopLarge} {
    width: 100%;
  }

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.mobile} {
    margin-bottom: 0;
  }
`;

const BoxTitleTexts = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 3.125rem;
  border-bottom: 0.063rem solid #c6c8cc;

  @media ${device.laptop} {
    width: 100%;
    margin-right: unset;
    padding-bottom: 0;
  }

  @media ${device.tablet} {
    width: 100%;
    margin-right: unset;
  }
`;

const BoxTexts = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;

  @media ${device.desktopLarge} {
    width: 100%;
    height: 90%;
  }

  @media ${device.laptop} {
    width: 100%;
    padding-bottom: 1.875rem;
  }

  @media ${device.tablet} {
    width: 100%;
    height: 90%;
    padding: 1.875rem 0;
  }

  @media ${device.mobile} {
    margin-bottom: unset;
  }

  > h2 {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 400;
    color: #231f20;
    margin-bottom: 3.125rem;

    @media ${device.tablet} {
      margin: 0;
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }

    @media ${device.mobile} {
      margin: 0;
      padding-bottom: 2rem;
    }
  }

  > h3 {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.625rem;

    @media ${device.tablet} {
      font-size: 0.875rem;
    }

    @media ${device.mobile} {
      line-height: 1.375rem;
    }
  }

  > p {
    width: 100%;
    font-size: 1rem;
    color: #231f20;
    line-height: 1.625rem;
    margin-bottom: ${props => (props.lastOne ? '3.125rem' : '2rem')};

    @media ${device.tablet} {
      margin-bottom: 1.5rem;
      line-height: 1.625rem;
      font-size: 0.875rem;
    }

    @media ${device.mobile} {
      width: 100%;
      line-height: 1.375rem;
    }
  }

  p:last-child {
    margin: 0;
  }
`;

const About = ({ markdown }) => (
  <Container>
    <BoxTitleTexts>
      <BoxTexts>
        <ReactMarkdown children={markdown} />
      </BoxTexts>
    </BoxTitleTexts>
  </Container>
);

export default About;
