// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// images
import MapImage from 'images/mapsImages/brasilia-index.png';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';

import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import About from 'components/units/unitPages/unit/about';
import AddressTimes from 'components/units/unitPages/unit/addressTimes';
import Services from 'components/units/unitPages/unit/services';
import OutDoor from 'components/units/unitPages/unit/outdoorService';
import HealthInsurance from 'components/HealthInsurance';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px 20px 20px'};
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  padding: ${props => props.padding};

  @media ${device.laptop} {
    padding: ${props => props.paddingLaptop};
    ${props =>
      props.borderBottomLaptop &&
      css`
        border-bottom: 1px solid #c6c8cc;
      `}

    ${props =>
      props.marginLaptop &&
      css`
        margin: ${props.marginLaptop};
      `}
  }
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (page, isDesktop) => {
  const components = page[0]?.components ?? [];
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part
          gridColumn={'1 / -1'}
          padding="0 0 4.375rem"
          paddingLaptop="0 0 1.25rem 0"
          marginLaptop="0 -1.25rem"
          id="convenio"
        >
          <HealthInsurance defaultUF="DF" />
        </Part>
        {!isDesktop && (
          <About
            markdown={getMarkdown(page, 'ckho6gq740d8f0a7381quub39', true)}
          />
        )}
        <Container>
          <Part gridColumn={'1 / span 5'}>
            {isDesktop && (
              <About
                markdown={getMarkdown(page, 'ckho6gq740d8f0a7381quub39', true)}
              />
            )}
            <AddressTimes
              markdown={getMarkdown(page, 'ckhousjio01x00b76oxsak0a6', true)}
              fragments={getMarkdown(page, 'ckhousjio01x00b76oxsak0a6')}
              pageName="brasilia"
              location={{ lat: -15.8351173, lng: -47.9117906 }}
              zoom={16}
              isWhatsapp
              mapPreview={MapImage}
              isStatic
              isDesktop={isDesktop}
            />
          </Part>
          <Part gridColumn={'7 / span 6'}>
            <Services
              markdown={getMarkdown(page, 'ckhoug2g001n309265d8aebu8', true)}
              pageName="brasilia"
              pageBrasilia
              isBrasilia
              cardHowCanIHelp
              isMarginLeft
            />
            <OutDoor
              fragments={getMarkdown(page, 'ckhoug2g001n309265d8aebu8')}
              pageName="Brasília"
            />
          </Part>
        </Container>
        {!isDesktop && (
          <OutDoor
            fragments={getMarkdown(page, 'ckhoug2g001n309265d8aebu8')}
            pageName="Brasília"
            url="/unidades/brasilia/detalhes"
            boxBottom
          />
        )}
        <Part gridColumn={'1 / -1'} isNotBorder isMarginFullWidth>
          {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
        </Part>
      </GridContainer>
    </>
  );
};

const Brasilia = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckho6ee3c0d600b23y4rm82sc', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, isDesktop)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckho67hmw0cyh0a73frirmcoa" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeBreaknews
                  breaknews {
                    id
                    name
                    type
                    singletexts
                    markdown
                  }
                  newnessBannerActive
                  newnessBanner {
                    id
                    name
                    type
                    markdown
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Brasilia page={response.gcms.site.pages} location={location} />;
      }}
    />
  );
};
